import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDeatilsReducer,
  productApkReducer,
} from './reducers/ProductReducers'

import { cartReducer } from './reducers/CartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  getPhoneCodeReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdatePasswordReducer,
  
} from './reducers/UserReducers'

import {
  getAddressPageReducer,
} from './reducers/AddressReducers'

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayCodeReducer,
  orderPayStatusReducer,
  orderListMyReducer,
} from './reducers/OrderReducers'

import {
  stockListReducer,
} from './reducers/StockReducers'

import { 
  infoListReducer,
  infoDetailsReducer,
  infoConcatReducer,
 } from './reducers/InfoReducers'

 import { 
  areaListReducer,
  areaSecondListReducer,
 } from './reducers/AreaReducers'


const reducer = combineReducers({
  infoList: infoListReducer,
  infoDetails: infoDetailsReducer,
  infoConcat: infoConcatReducer,

  areaList: areaListReducer,
  areaSecondList: areaSecondListReducer,

  productList: productListReducer,
  productDetails: productDeatilsReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  phoneCode: getPhoneCodeReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  provinceList: getAddressPageReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPayCode: orderPayCodeReducer,
  orderPayStatus: orderPayStatusReducer,
  orderListMy: orderListMyReducer,
  productApkList: productApkReducer,
  stockList: stockListReducer,
})

//获取本地存储的购物车信息
const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []
//获取本地存储的登录用户信息
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

//获取本地存储的用户收货地址信息
const shippingAddressStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

//初始化state值
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
