import axios from "axios";
import Qs from "qs";

export default {
  /**
   * 获取账号详情
   * @param {*} accountParam 
   * @returns 
   */
   getAccountDetail(accountParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/user/getUserDetail",
      data: accountParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

   /**
    * 检查token
    * @param {*} accountParam 
    * @returns 
    */
    checkToken(accountParam) {
        return axios({
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "post",
          url: "/shop/user/checkToken",
          data: accountParam,
        }).then((res) => {
          //console.log("retValue=" + JSON.stringify(res));
          return res.data;
        });
      },

  /**
   * 更新账号密码
   * @param {*} accountParam 
   * @returns 
   */
   updateAccountPassword(accountParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/user/updateUserPassword",
      data: accountParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
 
};
