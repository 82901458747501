import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Row, Col, Image, ListGroup, Card, Button, ListGroupItem, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserDetails } from '../actions/UserActions'
//import { listMyOrder } from '../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../contents/UserContents'
import { Formik }  from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import Meta from '../components/Meta'

const ProfileScreen = ({ location, history }) => {
  const [message, setMessage] = useState(null)
  const [successCode, setSuccessCode] = useState(null)
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
 
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile 

  /* const orderListMy = useSelector((state) => state.orderListMy)
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy
 */
  useEffect(() => {
    //console.log("userInfo="+JSON.stringify(userInfo))
    if (!userInfo) {
      history.push('/login')
    } else {
      // if (!user || success) {
      //   dispatch({ type: USER_UPDATE_PROFILE_RESET })
      //   setSuccessCode(true);
      //   // 获取用户详情
      //   let accountParam = {
      //     id: userInfo.id,
      //     token: userInfo.token
      //   }
      //   dispatch(getUserDetails(accountParam))
      //   //dispatch(listMyOrder())
      // } else {
      //   /* console.log("测试设置用户名")
      //   setName(user.niceName) */
      // }
    }
  }, [dispatch, history, userInfo, user, success])


  return (
    <>
      <Link className='btn btn-dark my-3' to='/'>
        返回主页
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>

            <Col md={4}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>个人资料</h5></ListGroup.Item>
                  <ListGroup.Item>账号：{userInfo.name}</ListGroup.Item>
                  <ListGroup.Item>账号类型：{userInfo.accountType}</ListGroup.Item>
                  <ListGroup.Item>VIP到期：{userInfo.vipExpireTime}</ListGroup.Item>
              </ListGroup>
            </Col>

          </Row>
          </>
      )}
    </>
  )
}

export default ProfileScreen
