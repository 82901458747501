import {
    STOCK_LIST_REQUEST,
    STOCK_LIST_SUCCESS,
    STOCK_LIST_FAIL,
    STOCK_LIST_RESET,
  } from '../contents/StockContents'
  import { logout } from './UserActions'
  import AccountProductStockService from '../service/AccountProductStockService.js'

/**
 * 分页获取用户产品库存
 * @returns 
 */
 export const getStockPage = (openAccountId, token,pageNum) => async (dispatch, getState) => {
    try {
      dispatch({ type: STOCK_LIST_REQUEST })
      if(pageNum === null || pageNum === undefined) {
        pageNum = 1
      }

      let pageParam = {
        pageNum: pageNum,
        pageSize: 10
      }
      let accountInfo = {
        openAccountId: openAccountId,
        token: token,
        pageParam: pageParam
      }
      
      AccountProductStockService.getStockPageByAccount(accountInfo)
          .then(res => {
              console.log("getStockPage="+JSON.stringify(res));
              if(res == null) {
                  console.log("服务端异常！");
                  dispatch({ type: STOCK_LIST_FAIL, payload: "服务端异常" })
                  return ;
              } else if(res.retCode !== 200) {
                  dispatch({ type: STOCK_LIST_FAIL, payload: res.message })
                  // 重新登录
                  if(res.retCode === 901000) {
                    dispatch(logout());
                  }
                  return ;
              }
              dispatch({ type: STOCK_LIST_SUCCESS, payload: res.retValue })
          });
    } catch (error) {
      dispatch({
        type: STOCK_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }