import {
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_FAIL,
    AREA_SECOND_LIST_REQUEST,
    AREA_SECOND_LIST_SUCCESS,
    AREA_SECOND_LIST_FAIL,
  } from '../contents/AreaConstents'
  
  //获取所有一级地区reducer
  export const areaListReducer = (state = { areas: [] }, action) => {
    switch (action.type) {
      case AREA_LIST_REQUEST:
        return { areaLoading: true, areas: [] }
      case AREA_LIST_SUCCESS:
          //console.log("areaListReducer="+ JSON.stringify(action));
        return {
          areaLoading: false,
          areas: action.payload,
        }
      case AREA_LIST_FAIL:
        return { areaLoading: false, areaError: action.payload }
      default:
        return state
    }
  }
  
  //获取所有二级地区reducer
  export const areaSecondListReducer = (state = { areaSeconds: [] }, action) => {
    switch (action.type) {
      case AREA_SECOND_LIST_REQUEST:
        return { areaSecondLoading: true, areaSeconds: [] }
      case AREA_SECOND_LIST_SUCCESS:
          //console.log("areaSecondListReducer="+ JSON.stringify(action));
        return {
          areaSecondLoading: false,
          areaSeconds: action.payload,
        }
      case AREA_SECOND_LIST_FAIL:
        return { areaSecondLoading: false, areaSecondError: action.payload }
      default:
        return state
    }
  }
  
 
  