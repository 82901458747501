import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_FAIL,
} from '../contents/CartConstents'
import ProductService from '../service/ProductService.js'


/**
 * 添加到购物车action
 * @param {*} params 
 * @param {*} qty 
 * @returns 
 */
export const addToCart = (productKey, qty, classifyType) => async (dispatch, getState) => {
  let productParam = { 
    productKey: productKey
  }
  ProductService.getProductDetailByKey(productParam)
        .then(res => {
            //console.log("addToCart="+JSON.stringify(res));
            if(res == null || res.retCode !== 200) {
                console.log("服务端异常！");
                dispatch({ type: CART_FAIL, payload: "服务端异常" })
                return ;
            }
            if(res.retValue == null) {
                dispatch({ type: CART_FAIL, payload: "查询不到产品" })
                return ;
            }

            // 通过reducer存储到store中
            dispatch({
              type: CART_ADD_ITEM,
              payload: {
                productKey: res.retValue.productKey,
                productName: res.retValue.productName,
                image: res.retValue.image,
                oneMonthPrice: res.retValue.oneMonthPrice,
                threeMonthPrice: res.retValue.threeMonthPrice,
                sixMonthPrice: res.retValue.sixMonthPrice,
                oneYearPrice: res.retValue.oneYearPrice,
                stockNum: res.retValue.stockNum,
                qty,
                classifyType,
              },
            })

            //将购买物品添加到本地存储
            localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
        });

}

/**
 * 删除产品action
 * @param {*} productKey 
 * @returns 
 */
export const removeFromCart = (productKey) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: productKey,
  })
  // 更新本地存储
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

/**
 * 保存收货地址action
 * @param {*} data 
 * @returns 
 */
export const saveShippingAddress = (data) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })
  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

/**
 * 保存支付方式action 
 * @param {*} data 
 * @returns 
 */
export const savePaymentMethod = (data) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })
  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
