import {
  CITY_PROVINCE_REQUEST,
  CITY_PROVINCE_SUCCESS,
  CITY_PROVINCE_FAIL,
} from '../contents/AddressContents'


/**
 * 获取手机验证码reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const getAddressPageReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_PROVINCE_REQUEST:
      return { loading: true }
    case CITY_PROVINCE_SUCCESS:
      return { loading: false, provinceList: action.payload }
    case CITY_PROVINCE_FAIL:
      return { loading: false, provinceListError: action.payload }
    default:
      return state
  }
}