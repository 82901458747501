import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_RESET,
  ORDER_DELIVER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_RESET,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_CODE_REQUEST,
  ORDER_PAY_CODE_SUCCESS,
  ORDER_PAY_CODE_FAIL,
  ORDER_PAY_CODE_RESET,
  ORDER_PAY_STATUS_REQUEST,
  ORDER_PAY_STATUS_SUCCESS,
  ORDER_PAY_STATUS_FAIL,
} from '../contents/OrderContents'

/**
 * 创建订单reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true }
    case ORDER_CREATE_SUCCESS:
      return { loading: false, orderKey: action.payload, success: true }
    case ORDER_CREATE_FAIL:
      return { loading: false, orderError: action.payload }
    case ORDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

/**
 * 获取单个订单reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const orderDetailsReducer = (
  state = { orderDetailsLoading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { ...state, orderDetailsLoading: true }
    case ORDER_DETAILS_SUCCESS:
      return { orderDetailsLoading: false, order: action.payload }
    case ORDER_DETAILS_FAIL:
      return { orderDetailsLoading: false, orderDetailError: action.payload }
    default:
      return state
  }
}

/**
 * 获取支付二维码
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const orderPayCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_CODE_REQUEST:
      return { ...state, loading: true }
    case ORDER_PAY_CODE_SUCCESS:
      return { loading: false, payCode: action.payload }
    case ORDER_PAY_CODE_FAIL:
      return { loading: false, orderError: action.payload }
    case ORDER_PAY_CODE_RESET:
      return { payCode: null}
    default:
      return state
  }
}

/**
 * 获取支付状态
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
 export const orderPayStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_STATUS_REQUEST:
      return { ...state, loading: true }
    case ORDER_PAY_STATUS_SUCCESS:
      return { loading: false, payCode: action.payload }
    case ORDER_PAY_STATUS_FAIL:
      return { loading: false, orderError: action.payload }
    default:
      return state
  }
}


/**
 * 获取所有订单reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true }
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_LIST_FAIL:
      return { loading: false, orderError: action.payload }
    default:
      return state
  }
}

//订单支付后更新订单的付款状态reducer
export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return { loading: true }
    case ORDER_PAY_SUCCESS:
      return { loading: false, success: true }
    case ORDER_PAY_FAIL:
      return { loading: false, orderError: action.payload }
    case ORDER_PAY_RESET:
      return {}
    default:
      return state
  }
}

//订单支付后更新订单的发货状态reducer
export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return { loading: true }
    case ORDER_DELIVER_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELIVER_FAIL:
      return { loading: false, orderError: action.payload }
    case ORDER_DELIVER_RESET:
      return {}
    default:
      return state
  }
}

/**
 * 获取登录用户订单的reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const orderListMyReducer = (state = { orders: [],pageInfo: {}}, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return { loading: true }
    case ORDER_LIST_MY_SUCCESS:
      return { loading: false, orders: action.payload.list, pageInfo: action.payload }
    case ORDER_LIST_MY_FAIL:
      return { loading: false, orderError: action.payload }
    case ORDER_LIST_MY_RESET:
      return { orders: [] }
    default:
      return state
  }
}
