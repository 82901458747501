import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { updateUserPassword } from '../actions/UserActions'
//import { listMyOrder } from '../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../contents/UserContents'
import { Formik }  from 'formik'
import * as yup from 'yup'	
import FormContainer from '../components/FormContainer'
import { PHONE_CODE_SIGN_SALT } from '../contents/UserContents'
import md5 from 'js-md5'


let timeChange;

const PasswordScreen = ({ location, history }) => {
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [time, setTime] = useState(60);
  const [btnContent, setBtnContent] = useState('获取验证码');

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
 
  const userUpdatePassword = useSelector((state) => state.userUpdatePassword)
  const { success, error } = userUpdatePassword 

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } 
  }, [history, userInfo])

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(60);
      setBtnContent('获取验证码');
    }
  }, [time]);


  /**
   * 表单提交函数(更新用户资料)
   * @param {*} e 
   */
  const submitHandler = (values) => {
    let accountParam = {
        id: userInfo.id,
        token: userInfo.token,
        name: userInfo.name,
        accountPass: values.passwordOld,
        password1: values.password,
        password2: values.confirmPassword,
    }
    dispatch(updateUserPassword(accountParam))
  }

  /**
   * 校验逻辑
   */
  const schema = yup.object().shape({
    passwordOld: yup.string().required("请输入当前密码"),
    password: yup.string().required("请输入新密码"),
    confirmPassword: yup.string().required("请输入确认密码"),
  });

  return (
    <FormContainer>
        <h1>更新密码</h1>
        {success && <Message variant='success'>更新成功！</Message>}
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}

        <Formik
        validationSchema={schema}
        onSubmit={(values) => {
            submitHandler(values);
        }}
        initialValues={{
          passwordOld: '',
          password: '',
          confirmPassword: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId='passwordOld'>
              <Form.Label>当前密码：</Form.Label>
              <Form.Control
                type='password'
                placeholder='请输入当前密码'
                value={values.passwordOld}
                onChange={handleChange}
                isInvalid={!!errors.passwordOld}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.passwordOld}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label>密码：</Form.Label>
              <Form.Control
                type='password'
                placeholder='建议使用两种或两种以上字符组合'
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='confirmPassword'>
              <Form.Label>确认密码：</Form.Label>
              <Form.Control
                type='password'
                placeholder='请再次输入密码'
                value={values.confirmPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type='submit' variant='primary'>
              更改密码
            </Button>
          </Form>
        )}
        </Formik>
    </FormContainer>
  )
}

export default PasswordScreen
