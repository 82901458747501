import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/CartActions'

const CartScreen = ({match,location,history}) => {
  let productKey = match.params.productKey

  const params = location.search ? location.search.split('?')[1] : '';
  const paramList = params ? params.split('&') : null;
  const qty = paramList ? Number(paramList[0].split('=')[1]) : 1
  console.log('qty=' + qty);
  const classifyType = paramList ? paramList[1].split('=')[1] : 'oneMonth'
  console.log('classifyType=' + classifyType);
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  let productParam;
  let data;

  useEffect(() => {
    if (productKey != null && productKey !== undefined
       && qty != null && classifyType != null) {
      console.log("addToCart 进入， productKey=" + productKey + ",qty="+qty+", classifyType="+ classifyType)
      dispatch(addToCart(productKey, qty, classifyType))
    }
  }, [dispatch, productKey, qty, classifyType])

  //删除产品函数
  const removerFromCartHandler = (productKey) => {
    dispatch(removeFromCart(productKey))
  }
  //支付函数
  const checkoutHandler = () => {
    history.push('/login?redirect=payment')
  }

  /**
   * 总数计算
   * @param {*} acc 
   * @param {*} item 
   * @returns 
   */
  const totalHandler = (acc, item) => {
    let price = item.classifyType === 'oneMonth' 
    ? item.oneMonthPrice
    : item.classifyType === 'oneYear'
    ? item.oneYearPrice
    : 15
    return acc + item.qty * price;
  }

  return (
    <Row>
      <Col md={8}>
        <h1>购物车</h1>
        {cartItems.length === 0 ? (
          <Message>
            购物为空<Link to='/'>返回主页</Link>
          </Message>
        ) : (
          <ListGroup variant='flush'>
            {cartItems.map((item) => (
              <ListGroup.Item key={item.productKey}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.productName} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/products/${item.productKey}`}>{item.productName}</Link>
                  </Col>
                  <Col md={1}>¥{
                    item.classifyType === 'oneMonth' 
                    ? item.oneMonthPrice
                    : item.classifyType === 'threeMonth'
                    ? item.threeMonthPrice
                    : item.classifyType === 'sixMonth'
                    ? item.sixMonthPrice
                    : item.classifyType === 'oneYear'
                    ? item.oneYearPrice
                    : ''
                  }</Col>
                  <Col md={2}>
                    {
                      item.classifyType === 'oneMonth' 
                        ? '一月'
                        : item.classifyType === 'oneYear'
                        ? '一年'
                        : ''
                    }
                  </Col>
                  <Col>
                    <Button
                      type='button'
                      onClick={() => removerFromCartHandler(item.productKey)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>
                共计({cartItems.reduce((acc, item) => acc + item.qty, 0)})个产品
              </h2>
              ¥{cartItems.reduce((acc, item) => totalHandler(acc, item), 0)}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                去支付
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  )
}

export default CartScreen
