import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col ,Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { Formik }  from 'formik'
import * as yup from 'yup'	
import { PHONE_CODE_SIGN_SALT } from '../contents/UserContents'
import md5 from 'js-md5'
import {
  nameRegister,
} from '../actions/UserActions'

let timeChange;

const RegisterScreen = ({ location, history }) => {
  const [message, setMessage] = useState(null)
  const dispatch = useDispatch()
  const [time, setTime] = useState(60);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState('获取验证码');
  const [success, setSuccess] = useState('')

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, registerInfo } = userRegister
  const redirect = '/login'


  useEffect(() => {
    if (registerInfo) {
      // 设置注册成功
      setSuccess("success");
      
      // 延时跳转
      history.push(redirect);

    }
  }, [history, registerInfo, redirect])


  /**
   * 表单提交函数
   * @param {*} values 
   */
  const submitHandler = (values) => {
    console.log("进入");
    // 提交
    if (values.password !== values.confirmPassword) {
      setMessage('密码不匹配')
    } else {
      let registerParam = {
        name: values.name,
        password1: values.password,
        password2: values.confirmPassword,
      }

      dispatch(nameRegister(registerParam))
    }
  }

  /**
   * 校验逻辑
   */
  const schema = yup.object().shape({
    name: yup.string().required("请输入登录账号"),
    password: yup.string().min(6,"请输入6位以上密码").required("请输入密码"),
    confirmPassword: yup.string().min(6,"请输入6位以上确认密码").required("请输入确认密码"),
  });

  return (
    <FormContainer>
      <h1>注册</h1>
      {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      {success && <Alert variant='success'>注册成功</Alert>}

      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
            submitHandler(values);
        }}
        initialValues={{
          name: '',
          password: '',
          confirmPassword: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId='name'>
          <Form.Label>登录账号：</Form.Label>
          <Form.Control
            type='name'
            placeholder='您的登录账号'
            value={values.name}
            onChange={handleChange}
            isInvalid={!!errors.name}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='password'>
          <Form.Label>密码：</Form.Label>
          <Form.Control
            type='password'
            placeholder='建议使用两种或两种以上字符组合'
            value={values.password}
            onChange={handleChange}
            isInvalid={!!errors.password}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='confirmPassword'>
          <Form.Label>确认密码：</Form.Label>
          <Form.Control
            type='password'
            placeholder='请再次输入密码'
            value={values.confirmPassword}
            onChange={handleChange}
            isInvalid={!!errors.confirmPassword}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword}
          </Form.Control.Feedback>
        </Form.Group>
        <Button type='submit' variant='primary'>
          注册
        </Button>
      </Form>
      )}
      </Formik>

      <Row className='py-3'>
        <Col>
          已有账户？
        </Col>
        <Col>
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            <h5>去登录</h5>
          </Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default RegisterScreen
