import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Rating from './Rating'

const Info = ({ info, areaId, areaSecondId, pageNum }) => {
  return (
    // <Card style={{ width: '18rem' }}>
    //   <Card.Img variant="top" src={info.icon} style={{ width: '18rem',height: '18rem' }}/>
    //   <Card.Body>
    //     <Card.Title>Card Title</Card.Title>
    //     <Card.Text>
    //       Some quick example text to build on the card title and make up the
    //       bulk of the card's content.
    //     </Card.Text>
    //   </Card.Body>
    // </Card>

    <Card style={{ width: '14rem', margin: '1rem'}}>
      <Link to={`/infos/${info.id}/${areaId}/${areaSecondId}/${pageNum}`}>
        <Card.Img src={info.icon} variant='top' style={{ width: '14rem',height: '14rem' }} />
      </Link>
      <Card.Body>
        <Link to={`/infos/${info.id}/${areaId}/${areaSecondId}/${pageNum}`}>
          <Card.Title>{info.title}</Card.Title>
        </Link>
        <Card.Text >{info.addTime}</Card.Text>
        <Card.Text >{info.des}</Card.Text>
        <Card.Text as='h6'>{info.areaName}</Card.Text>
      </Card.Body>
    </Card> 
  )
}

export default Info
