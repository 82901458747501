import axios from "axios";
import Qs from "qs";

export default {

  /**
   * 根据账号获取库存
   * @param {*} accountInfo 
   * @returns 
   */
   getStockPageByAccount(accountInfo) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/api/accountProductStockView/getStockPageByAccount",
      data: accountInfo,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

 
};
