import React, { useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { savePaymentMethod } from '../actions/CartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import { Formik }  from 'formik'
import * as yup from 'yup'	

const PaymenScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)

  const dispatch = useDispatch()
  const submitHandler = (values) => {
    dispatch(savePaymentMethod(values.paymentMethod))
    history.push('/placeorder')
  }

  /**
   * 校验逻辑
   */
   const schema = yup.object().shape({
      paymentMethod: yup.string().required("请选择支付方式"),
    });

  return (
    <FormContainer>
      <CheckoutSteps step1 />
      <h1>支付方式</h1>

      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          //console.log("paymentMethod="+values.paymentMethod)
            submitHandler(values);
        }}
        initialValues={{
          paymentMethod: 'weixin',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='paymentMethod'>
                <Col>
                  <Form.Check
                    type='radio'
                    label='微信'
                    id='微信'
                    name='paymentMethod'
                    value='weixin'
                    onChange={e => setFieldValue('paymentMethod', e.target.value)}
                    isInvalid={!!errors.paymentMethod}
                    checked
                  ></Form.Check>
                 {/*  <Form.Check
                    type='radio'
                    label='支付宝'
                    id='支付宝'
                    name='paymentMethod'
                    value='zhifubao'
                    onChange={e => setFieldValue('paymentMethod', e.target.value)}
                    isInvalid={!!errors.paymentMethod}
                  ></Form.Check> */}
                </Col>
            </Form.Group>
            <Button type='submit' variant='primary'>
              继续下一步
            </Button>
          </Form>
      )}
      </Formik>
    </FormContainer>
  )
}

export default PaymenScreen
