import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_CODE_REQUEST,
  ORDER_PAY_CODE_SUCCESS,
  ORDER_PAY_CODE_FAIL,
} from '../contents/OrderContents'
import axios from 'axios'
import { logout } from './UserActions'
import { CART_CLEAR_ITEMS } from '../contents/CartConstents'
import OrderService from '../service/OrderService.js'
import PaymentService from '../service/PaymentService.js'


/**
 * 创建订单Action
 * @param {*} order 
 * @returns 
 */
export const createOrder = (orderParam) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CREATE_REQUEST })

    OrderService.insertOrder(orderParam)
        .then(res => {
            //console.log("createOrder="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: ORDER_CREATE_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: ORDER_CREATE_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: ORDER_CREATE_SUCCESS, payload: res.retValue })
            //dispatch({ type: CART_CLEAR_ITEMS })
            // 更新本地存储
            //console.log("cartItems=" + JSON.stringify(getState().cart.cartItems))
            //localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
        });
    
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * 获取单个订单Action
 */
export const getOrderDetails = (orderKey, accountId, token) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST })
    let orderParam = {
      accountId: accountId,
      token: token,
      orderKey: orderKey
    }

    OrderService.getDetailByOrderKey(orderParam)
        .then(res => {
            //console.log("getOrderDetails="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: ORDER_DETAILS_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: ORDER_DETAILS_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }

            dispatch({ type: ORDER_DETAILS_SUCCESS, payload: res.retValue })
        });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * 获取微信二维码
 * @returns 
 */
export const getPayCode = (orderKey) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_PAY_CODE_REQUEST })
    let paymentParam = {
      orderKey: orderKey
    }

    PaymentService.getPayCode(paymentParam)
        .then(res => {
            //console.log("getPayCode="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: ORDER_PAY_CODE_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: ORDER_PAY_CODE_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: ORDER_PAY_CODE_SUCCESS, payload: res.retValue })
        });
  } catch (error) {
    dispatch({
      type: ORDER_PAY_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}



