import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../contents/CartConstents'

//购物车reducer
export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      //console.log("CART_ADD_ITEM 进入")
      const item = action.payload

      const existItem = state.cartItems.find((x) => x.productKey === item.productKey)

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.productKey === existItem.productKey ? item : x
          ),
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        }
      }
    case CART_REMOVE_ITEM:
      //console.log("CART_REMOVE_ITEM 进入")
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.productKey !== action.payload),
      }
    case CART_CLEAR_ITEMS:
      //console.log("CART_CLEAR_ITEMS 进入")
      return { 
        ...state, 
        cartItems: state.cartItems.filter((x) => x.productKey === null),
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      //console.log("CART_SAVE_SHIPPING_ADDRESS 进入")
      return { ...state, shippingAddress: action.payload }
    case CART_SAVE_PAYMENT_METHOD:
      //console.log("CART_SAVE_PAYMENT_METHOD 进入")
      return { ...state, paymentMethod: action.payload }
    default:
      return state
  }
}
