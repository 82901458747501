import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, Table,Pagination} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { Formik }  from 'formik'
import * as yup from 'yup'
import { getStockPage } from '../actions/StockAction'
import moment from 'moment';

const StockScreen = ({ location, history }) => {
  const [message, setMessage] = useState(null)
  const [successCode, setSuccessCode] = useState(null)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
 
  const stockList = useSelector((state) => state.stockList)
  const { loading: loadingStocks, error: errorStocks, stocks, pageInfo } = stockList

  const [pageList, setPageList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageLast, setPageLast] = useState(1)

  useEffect(() => {
    if (userInfo == null || userInfo === undefined || userInfo==="") {
      history.push('/login')
    } else {
      dispatch(getStockPage(userInfo.openAccountId, userInfo.token, 1))
    }
  }, [dispatch, history, userInfo])

  /**
   * 分页参数
   */
  useEffect(() => {
    if(pageInfo !== null && pageInfo !== undefined) {
      setPageNum(pageInfo.pageNum);
      setPageLast(pageInfo.lastPage)
      if(pageInfo.pageNum <= 5 && pageInfo.lastPage > 5) {
        console.log("pageInfo.pageNum <= 5 && pageInfo.lastPage > 5")
        let list = [1,2,3,4,5];
        setPageList(list);
      } else if(pageInfo.lastPage <= 5){
        console.log("pageInfo.lastPage <= 5")
        let list = [];
        for(let i=1; i<= pageInfo.lastPage; i++ ) {
          list[i-1] = i;
        }
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum <= pageInfo.lastPage -2) {
        console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  <= pageInfo.lastPage -2")
        let list = [];
        list[0] = pageInfo.pageNum - 2;
        list[1] = pageInfo.pageNum - 1;
        list[2] = pageInfo.pageNum;
        list[3] = pageInfo.pageNum + 1;
        list[4] = pageInfo.pageNum + 2;
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.lastPage-1) {
        console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage -1")
        let list = [];
        list[0] = pageInfo.pageNum - 3;
        list[1] = pageInfo.pageNum - 2;
        list[2] = pageInfo.pageNum - 1;
        list[3] = pageInfo.pageNum;
        list[4] = pageInfo.pageNum + 1;
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.lastPage) {
        console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage")
        let list = [];
        list[0] = pageInfo.pageNum - 4;
        list[1] = pageInfo.pageNum - 3;
        list[2] = pageInfo.pageNum - 2;
        list[3] = pageInfo.pageNum - 1;
        list[4] = pageInfo.pageNum;
        setPageList(list);
      }
    }
  }, [pageInfo])

  /**
   * 分页查询
   * @param {*} pageNum 
   */
  const handlePage= (pageNum) => {
    //console.log("pageNum="+pageNum);
    if(pageNum>= pageLast) {
      pageNum = pageLast
    } else if (pageNum <= 1) {
      pageNum = 1
    }
    dispatch(getStockPage(userInfo.openAccountId, userInfo.token, pageNum))
  }

  return (
    <Row>
        <h2>我的库存</h2>
        {loadingStocks ? (
          <Loader />
        ) : errorStocks ? (
          <Message variant='danger'>{errorStocks}</Message>
        ) : (
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr >
                <th>产品key</th>
                <th>产品名</th>
                <th>一月库存数</th>
                <th>一年库存数</th>
                <th>产品详情</th>
              </tr>
            </thead>
            <tbody>
              {stocks.map((stock, index) => (
                <tr key={index}>
                  <td>{stock.productKey}</td>
                  <td>{stock.productName}</td>
                  <td>{stock.oneMonthStock}</td>
                  <td>{stock.oneYearStock}</td>
                  <td>
                    <LinkContainer to={`/products/${stock.productKey}`}>
                      <Button variant='light' className='btn-sm'>
                        产品详情
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Pagination size="lg">
                <Pagination.First onClick={() => handlePage(1)} />
                <Pagination.Prev onClick={() => handlePage(pageNum-1)}/>
                {
                  pageList.map((page, index) => (
                    <Pagination.Item key={index} active={page === pageNum} onClick={() => handlePage(page)}>{page}</Pagination.Item>
                  ))
                }
                <Pagination.Next onClick={() => handlePage(pageNum+1)}/>
                <Pagination.Last onClick={() => handlePage(pageLast)}/>
        </Pagination>
    </Row>
  )
}

export default StockScreen
