import {
  STOCK_LIST_REQUEST,
  STOCK_LIST_SUCCESS,
  STOCK_LIST_FAIL,
  STOCK_LIST_RESET,
} from '../contents/StockContents'

/**
 * 获取库存的reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
 export const stockListReducer = (state = { stocks: [],pageInfo: {}}, action) => {
  switch (action.type) {
    case STOCK_LIST_REQUEST:
      return { loading: true }
    case STOCK_LIST_SUCCESS:
      return { loading: false, stocks: action.payload.list, pageInfo: action.payload }
    case STOCK_LIST_FAIL:
      return { loading: false, error: action.payload }
    case STOCK_LIST_RESET:
      return { stocks: [] }
    default:
      return state
  }
}