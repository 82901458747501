import axios from "axios";
import Qs from "qs";

export default {

  /**
   * 获取所有搜索url
   * @returns 
   */
   getProductDetailByKey(productParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/product/getProductDetailByKey",
      data: productParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

 
};
