import {
    INFO_LIST_REQUEST,
    INFO_LIST_SUCCESS,
    INFO_LIST_FAIL,
    INFO_DETAILS_REQUEST,
    INFO_DETAILS_SUCCESS,
    INFO_DETAILS_FAIL,
    INFO_CONCAT_FAIL,
    INFO_CONCAT_REQUEST,
    INFO_CONCAT_SUCCESS,
  } from '../contents/InfoConstents'
  
  //获取所有信息的reducer
  export const infoListReducer = (state = { infos: [], pageInfo: {} }, action) => {
    switch (action.type) {
      case INFO_LIST_REQUEST:
        return { loading: true, infos: [] }
      case INFO_LIST_SUCCESS:
          //console.log("infoListReducer="+ JSON.stringify(action));
        return {
          loading: false,
          infos: action.payload.list,
          pageInfo: action.payload,
        }
      case INFO_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  //获取信息详情
  export const infoDetailsReducer = (state = { info: {} }, action) => {
    switch (action.type) {
      case INFO_DETAILS_REQUEST:
        return { loading: true, ...state }
      case INFO_DETAILS_SUCCESS:
        return { loading: false, info: action.payload }
      case INFO_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

    //获取信息联系方式
    export const infoConcatReducer = (state = { info: {} }, action) => {
      switch (action.type) {
        case INFO_CONCAT_REQUEST:
          return { loading: true, ...state }
        case INFO_CONCAT_SUCCESS:
          return { loading: false, info: action.payload }
        case INFO_CONCAT_FAIL:
          return { loading: false, error: action.payload }
        default:
          return state
      }
    }
  
 
  