import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/UserActions'
import FormContainer from '../components/FormContainer'
import { Formik }  from 'formik'
import * as yup from 'yup'	
import {
  nameLogin
} from '../actions/UserActions'

const LoginScreen = ({ location, history }) => {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      // 获取到用户信息后，重定向到之前的页面
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  /**
   * 表单提交函数
   * @param {*} values 
   */
   const submitHandler = (values) => {
    console.log("submitHandler 进入");
    // 提交
    let loginShopParam = {
        name : values.name,
        password : values.password,
    }
    dispatch(nameLogin(loginShopParam))
  }

  /**
   * 校验逻辑
   */
   const schema = yup.object().shape({
    name: yup.string().required("请输入账号"),
    password: yup.string().min(6,"请输入6位以上密码").required("请输入密码"),
  });

  return (
    <FormContainer>
      <h1>登录</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
            console.log(JSON.stringify(values));

            submitHandler(values);
        }}
        initialValues={{
          name: '',
          password: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId='name'>
          <Form.Label>账号：</Form.Label>
          <Form.Control
            type='name'
            placeholder='请输入账号'
            value={values.name}
            onChange={handleChange}
            isInvalid={!!errors.name}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>密码：</Form.Label>
          <Form.Control
            type='password'
            placeholder='请输入密码'
            value={values.password}
            onChange={handleChange}
            isInvalid={!!errors.password}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type='submit' variant='primary'>
          登录
        </Button>
      </Form>
      )}
      </Formik>

      <Row className='py-3'>
        <Col>
          新用户？
        </Col>
        <Col>
          <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
            <h5>去注册</h5>
          </Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default LoginScreen
