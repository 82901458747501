import React, { useState, useEffect } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  ListGroup,
  Row,
  Col,
  Image,
  Card,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrderDetails,
  getPayCode,
} from '../actions/OrderActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import axios from 'axios'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../contents/OrderContents'
import { v4 as uuidv4 } from 'uuid'
import QRCode  from 'qrcode.react';
import moment from 'moment';
import PaymentService from '../service/PaymentService.js'

const OrderScreen = ({ match, history }) => {
  const orderKey = match.params.orderKey
  const dispatch = useDispatch()
  //弹出框的状态
  const [show, setShow] = useState(false)
  // 扫描时间戳
  const [timer, setTimer] = useState(null);

  //支付二维码图片
  const [image, setImage] = useState('')
  const [text, setText] = useState('请扫码')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPayCode = useSelector((state) => state.orderPayCode)
  const { payCode } = orderPayCode

  const productApkList = useSelector((state) => state.productApkList)
  const { apkList } = productApkList
  

  const paymentShow = useSelector((state) => {
    if(order !== null && order !== undefined) {
      if(order.paymentMethod === 'weixin') {
        return '微信'
      } else if(order.paymentMethod === 'zhifubao') {
        return '支付宝'
      } 
    }
  })

  //计算价格
  if (!loading) {
    if(order !== null && order !== undefined) {
      order.itemsPrice = order.orderItemList.reduce((acc, item) => acc + item.price * item.qty, 0)
    }
  }


  /**
   * 获取订单详情，并列出产品apk
   */
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (order == null || order.orderKey !== orderKey) {
      //console.log("order="+ JSON.stringify(order))
      dispatch(getOrderDetails(orderKey, userInfo.id, userInfo.token))
    } 

    // eslint-disable-next-line
  }, [dispatch, history, userInfo, order, orderKey])

  /**
   * 微信二维码
   */
  useEffect(() => {
    if(payCode !== null && payCode !== undefined) {
      if(payCode.codeUrl === null || payCode.codeUrl === undefined) {
        return ;
      } 
      //获取微信返回的支付二维码图片
      setImage(payCode.codeUrl)

      //设置定时器去监听支付
      var timesRun = 0;
      let timer = setInterval(() => {
        //请求支付status
        let paymentParam = {
          orderKey: orderKey
        }

        PaymentService.getPayStatus(paymentParam)
            .then(res => {
                //console.log("getPayStatus="+JSON.stringify(res));
                if(res == null) {
                    console.log("服务端异常！");
                    setText('服务端异常');
                    return ;
                } else if(res.retCode !== 200) {
                    setText(res.message);
                    return ;
                }
                
                if (res.retValue !== null && res.retValue.paidFlag === 1) {
                  //创建支付结果对象
                  const paymentResult = {
                    id: uuidv4(),
                    status: res.retValue.paidFlag,
                    updata_time: Date.now(),
                  }
                  //更新完成支付的订单
                  setText('您已经支付成功')
                  setShow(false)
                  clearTimeout(timer)

                  // 更新数据
                  dispatch(getOrderDetails(orderKey, userInfo.id, userInfo.token))
                } else {
                  setText('请扫码')
                }
            });
        // 10分钟超时
        timesRun = timesRun + 1;
        if(timesRun >= 100) {
          clearTimeout(timer)
        }
      }, 3000)
      setTimer(timer);
    }
  }, [dispatch, history, payCode, orderKey, userInfo])


  //创建开启和关闭弹出框的函数
  const handleClose = () => {
    setShow(false)
    clearTimeout(timer)
  }

  /**
   * 支付操作
   */
  const handlePayment = () => {
    // 获取支付二维码
    dispatch(getPayCode(order.orderKey));
    setShow(true);
  }


  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <h2>订单号：{order.orderKey}</h2>
      <Row key="-4">
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>支付方式</h2>
              <p>
                <strong>支付方法：</strong>
                {paymentShow}
              </p>
              {order.paidFlag ? (
                <Message variant='success'>已支付，支付时间：{moment(order.paidTime).format("YYYY-MM-DD HH:mm:ss")}</Message>
              ) : (
                <Message variant='warning'>待支付</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>产品订单</h2>
              {order.orderItemList.length === 0 ? (
                <Message>购物车为空</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItemList.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row key={index}>
                        <Col md={1} key={index+1}>
                          <Image
                            src={item.image}
                            alt={item.productName}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col key={index+2}>
                          <Link to={`/products/${item.productKey}`}>
                            {item.productName}
                          </Link>
                        </Col>
                        <Col md={4} key={index+3}>
                          {item.qty} X {item.price} = {item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
            
            {order.paidFlag === 1 && apkList !== undefined ? (
              <ListGroup.Item>
                  <h2>产品扫码下载</h2>
                  <ListGroup variant='flush'>
                    {apkList.map((item,index) => (
                      <ListGroup.Item key={index}>
                        <p><strong> 产品: {item.productName}</strong></p>
                        {item.productApkList != null && item.productApkList[0].apkWebUrl != null  ? (
                          <QRCode
                            value={item.productApkList[0].apkWebUrl}  //value参数为生成二维码的链接
                            size={200} //二维码的宽高尺寸
                            fgColor="#000000"  //二维码的颜色
                          />
                        ) : null}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
              </ListGroup.Item>
            ) : null}
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>订单详情</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>产品总价</Col>
                  <Col>¥ {order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>订单总价</Col>
                  <Col>¥ {order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              
              {!order.paidFlag && order.paymentMethod === 'weixin' && (
                <ListGroup.Item>
                  {/* 微信支付BTN */}
                  <Button
                    type='button'
                    className='btn-block'
                    onClick={handlePayment}
                    disabled={order.orderItemList === 0}
                  >
                    去支付
                  </Button>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>订单号：{order.orderKey}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>支付金额： ¥{order.totalPrice}</p>
                      <p>支付方式： {paymentShow}</p>
                      <Row>
                        <Col md={6} style={{ textAlign: 'center' }}>
                          <QRCode
                                value={image}
                                size={200}
                                fgColor="#000000" 
                          />
                          <p
                            style={{
                              backgroundColor: '#00C800',
                              color: 'white',
                            }}
                          >
                            {text}
                          </p>
                        </Col>
                        <Col>
                          <Image src='/images/saoyisao.jpg' />
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant='primary' onClick={handleClose}>
                        关闭
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </ListGroup.Item>
              )}

            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
