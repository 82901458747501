import axios from "axios";
import Qs from "qs";

export default {
  /**
   * 获取支付码
   * @param {*} paymentParam 
   * @returns 
   */
   getPayCode(paymentParam) {
    //console.log("getPayCode paymentParam="+JSON.stringify(paymentParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/payment/getPayCode",
      data: paymentParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 获取支付码
   * @param {*} paymentParam 
   * @returns 
   */
   getPayStatus(paymentParam) {
    //console.log("getPayStatus paymentParam="+JSON.stringify(paymentParam));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/payment/getPayStatus",
      data: paymentParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 关闭支付
   * @param {*} paymentParam 
   * @returns 
   */
   closePay(paymentParam) {
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        url: "/shop/payment/closePay",
        data: paymentParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

 
};
