import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, ListGroup, Row, Col, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../actions/OrderActions'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { ORDER_CREATE_RESET } from '../contents/OrderContents'
import { USER_DETAILS_RESET } from '../contents/UserContents'

const PlaceorderScreen = ({ history }) => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)

    /**
   * 计算价格
   * @param {*} acc 
   * @param {*} item 
   * @returns 
   */
  const totalHandler = (acc, item) => {
      let price = item.classifyType === 'oneMonth' 
      ? item.oneMonthPrice
      : item.classifyType === 'threeMonth'
      ? item.threeMonthPrice
      : item.classifyType === 'sixMonth'
      ? item.sixMonthPrice
      : item.classifyType === 'oneYear'
      ? item.oneYearPrice
      : 15
      return acc + item.qty * price;
    }
  //计算价格
  cart.itemsPrice = cart.cartItems.reduce((acc, item) => totalHandler(acc, item), 0)
  cart.shippingPrice = 0;
  cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice);

  //console.log("cart=" + JSON.stringify(cart));

  const orderCreate = useSelector((state) => state.orderCreate)
  const { orderKey, success, error } = orderCreate
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  
  const paymentShow = useSelector((state) => {if(cart.paymentMethod == 'weixin') {
      return '微信'
    } else if(cart.paymentMethod == 'zhifubao') {
      return '支付宝'
    } 
  })

  
  useEffect(() => {
    if (success) {
      //console.log("orderCreate=" + JSON.stringify(orderCreate))
      history.push(`/order/${orderKey}`)
      dispatch({ type: ORDER_CREATE_RESET })
      dispatch({
        type: USER_DETAILS_RESET,
      })
    }
    // eslint-disable-next-line
  }, [history, success, orderKey, orderCreate])


  /**
   * 提交订单
   */
  const placeorderHandler = () => {
    let orderItemList = [];
    for(let i=0; i< cart.cartItems.length; i++)  { 
      let item = cart.cartItems[i];
      let price = item.classifyType === 'oneMonth' 
          ? item.oneMonthPrice
          : item.classifyType === 'threeMonth'
          ? item.threeMonthPrice
          : item.classifyType === 'sixMonth'
          ? item.sixMonthPrice
          : item.classifyType === 'oneYear'
          ? item.oneYearPrice
          : 15;

      let orderItem = {
        productKey: item.productKey,
        productName: item.productName,
        image: item.image,
        qty: item.qty,
        classifyType: item.classifyType,
        price: price
      }
      orderItemList[i] = orderItem;
    }

    dispatch(
      createOrder({
        orderItemList: orderItemList,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        totalPrice: cart.totalPrice,
        openAccountId: userInfo.openAccountId,
        token: userInfo.token
      })
    )
  }

  
  return (
    <>
      <CheckoutSteps step1 step2 />
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>支付方式</h2>
              <strong>支付方法：</strong>
              {paymentShow}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>产品订单</h2>
              {cart.cartItems.length === 0 ? (
                <Message>购物车为空</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.productName}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/products/${item.productKey}`}>
                            {item.productName}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} X {item.classifyType === 'oneMonth' 
                          ? item.oneMonthPrice
                          : item.classifyType === 'threeMonth'
                          ? item.threeMonthPrice
                          : item.classifyType === 'sixMonth'
                          ? item.sixMonthPrice
                          : item.classifyType === 'oneYear'
                          ? item.oneYearPrice
                          : 15} = {item.qty * (item.classifyType === 'oneMonth' 
                          ? item.oneMonthPrice
                          : item.classifyType === 'threeMonth'
                          ? item.threeMonthPrice
                          : item.classifyType === 'sixMonth'
                          ? item.sixMonthPrice
                          : item.classifyType === 'oneYear'
                          ? item.oneYearPrice
                          : 15)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>订单详情</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>产品总价</Col>
                  <Col>¥ {cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>订单总价</Col>
                  <Col>¥ {cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && <Message variant='danger'>{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block'
                  onClick={placeorderHandler}
                  disabled={cart.cartItems === 0}
                >
                  提交订单
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PlaceorderScreen
