import {
    INFO_DETAILS_FAIL,
    INFO_DETAILS_REQUEST,
    INFO_DETAILS_SUCCESS,
    INFO_LIST_FAIL,
    INFO_LIST_REQUEST,
    INFO_LIST_SUCCESS,
    INFO_CONCAT_FAIL,
    INFO_CONCAT_REQUEST,
    INFO_CONCAT_SUCCESS,
  } from '../contents/InfoConstents'
  import { logout } from './UserActions'
  import InfoService from '../service/InfoService.js'
  
  // 分页获取信息
  export const listInfos = (infoParam) => async (
    dispatch
  ) => {
    try {
      dispatch({ type: INFO_LIST_REQUEST })
      InfoService.getInfoPage(infoParam)
        .then(res => {
            //console.log("listProducts="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: INFO_LIST_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: INFO_LIST_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: INFO_LIST_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: INFO_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
  //获取信息详情
  export const listInfoDetails = (infoParam) => async (dispatch) => {
    try {
      dispatch({ type: INFO_DETAILS_REQUEST })
      InfoService.getInfoDetail(infoParam)
        .then(res => {
            //console.log("listProductDetails="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: INFO_DETAILS_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: INFO_DETAILS_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            if(res.retValue == null) {
                dispatch({ type: INFO_DETAILS_FAIL, payload: "查询不到产品" })
                return ;
            }

            dispatch({ type: INFO_DETAILS_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: INFO_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
 

  
  //获取联系方式
  export const listInfoConcat = (infoParam) => async (dispatch) => {
    try {
      dispatch({ type: INFO_CONCAT_REQUEST })
      InfoService.getInfoConcat(infoParam)
        .then(res => {
            //console.log("listProductDetails="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: INFO_CONCAT_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: INFO_CONCAT_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            if(res.retValue == null) {
                dispatch({ type: INFO_CONCAT_FAIL, payload: "查询不到产品" })
                return ;
            }

            dispatch({ type: INFO_CONCAT_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: INFO_CONCAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }