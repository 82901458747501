import {
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_REVIEW_FAIL,
    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_APK_REQUEST,
    PRODUCT_APK_SUCCESS,
    PRODUCT_APK_FAIL,
  } from '../contents/ProductConstents'
  
  import { logout } from './UserActions'
  import ProductService from '../service/ProductService.js'
  
  
  //获取单个产品action
  export const listProductDetails = (productParam) => async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_DETAILS_REQUEST })
      ProductService.getProductDetailByKey(productParam)
        .then(res => {
            //console.log("listProductDetails="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: PRODUCT_DETAILS_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: PRODUCT_DETAILS_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            if(res.retValue == null) {
                dispatch({ type: PRODUCT_DETAILS_FAIL, payload: "查询不到产品" })
                return ;
            }

            dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
  
  

