export const INFO_LIST_REQUEST = 'INFO_LIST_REQUEST'
export const INFO_LIST_SUCCESS = 'INFO_LIST_SUCCESS'
export const INFO_LIST_FAIL = 'INFO_LIST_FAIL'

export const INFO_DETAILS_REQUEST = 'INFO_DETAILS_REQUEST'
export const INFO_DETAILS_SUCCESS = 'INFO_DETAILS_SUCCESS'
export const INFO_DETAILS_FAIL = 'INFO_DETAILS_FAIL'
export const INFO_DETAILS_RESET = 'INFO_DETAILS_RESET'

export const INFO_CONCAT_REQUEST = 'INFO_CONCAT_REQUEST'
export const INFO_CONCAT_SUCCESS = 'INFO_CONCAT_SUCCESS'
export const INFO_CONCAT_FAIL = 'INFO_CONCAT_FAIL'
