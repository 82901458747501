import axios from "axios";
import Qs from "qs";

export default {

  /**
   * 分页获取信息
   * @param {*} info 
   * @returns 
   */
  getInfoPage(info) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/info/getInfoPage",
      data: info,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取信息详情
   * @param {*} info 
   * @returns 
   */
  getInfoDetail(info) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/info/getInfoDetail",
      data: info,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
 
    /**
   * 获取信息联系方式
   * @param {*} info 
   * @returns 
   */
    getInfoConcat(info) {
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        url: "/shop/info/getInfoConcat",
        data: info,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
};
