import axios from "axios";
import Qs from "qs";

export default {
  /**
   * 登录
   * @returns 
   */
  nameLogin(loginShopParam) {
    return axios({
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      method: "post",
      url: "/shop/login/nameLogin",
      data: loginShopParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 注册
   * @param {*} registerParam 
   * @returns 
   */
   nameRegister(registerParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/login/nameRegister",
      data: registerParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
 
};
