import {
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_FAIL,
    AREA_SECOND_LIST_REQUEST,
    AREA_SECOND_LIST_SUCCESS,
    AREA_SECOND_LIST_FAIL,
  } from '../contents/AreaConstents'
  import { logout } from './UserActions'
  import AreaService from '../service/AreaService.js'
  
  // 获取一级地区信息
  export const areaInfos = () => async (
    dispatch
  ) => {
    try {
      dispatch({ type: AREA_LIST_REQUEST })
      AreaService.getOneList()
        .then(res => {
            //console.log("listProducts="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: AREA_LIST_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: AREA_LIST_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: AREA_LIST_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: AREA_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
  //获取信息详情
  export const areaSecondInfos = (areaParam) => async (dispatch) => {
    try {
      dispatch({ type: AREA_SECOND_LIST_REQUEST })
      AreaService.getSecondList(areaParam)
        .then(res => {
            //console.log("listProducts="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: AREA_SECOND_LIST_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: AREA_SECOND_LIST_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: AREA_SECOND_LIST_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: AREA_SECOND_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
