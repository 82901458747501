import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  PHONE_CODE_REQUEST,
  PHONE_CODE_SUCCESS,
  PHONE_CODE_FAIL,
  USER_UPDATE_PASSWORD_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
} from '../contents/UserContents'
import axios from 'axios'
import { ORDER_LIST_MY_RESET } from '../contents/OrderContents'
import LoginService from '../service/LoginService.js'
import AccountService from '../service/AccountService.js'


//用户注册Action
export const nameRegister = (registerParam) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST })

    LoginService.nameRegister(registerParam)
      .then(res => {
          console.log("register="+JSON.stringify(res));
          if(res == null ) {
              dispatch({ 
                type: USER_REGISTER_FAIL, 
                payload: "后端服务异常"
               })
              return ;
          } else if(res.retCode !== 200) {
            dispatch({ 
              type: USER_REGISTER_FAIL, 
              payload: res.message
             })
            return ;
          }

          dispatch({ type: USER_REGISTER_SUCCESS, payload: res.retValue })
      });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}


/**
 * 登录
 * @param {*} params 
 * @returns 
 */
export const nameLogin = (loginShopParam) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })
    LoginService.nameLogin(loginShopParam)
      .then(res => {
          console.log("nameLogin="+JSON.stringify(res));
          if(res == null ) {
              dispatch({ 
                type: USER_LOGIN_FAIL, 
                payload: "后端服务异常"
               })
              return ;
          } else if(res.retCode !== 200) {
            dispatch({ 
              type: USER_LOGIN_FAIL, 
              payload: res.message
             })
            return ;
          }
          // 数据存储
          dispatch({ type: USER_LOGIN_SUCCESS, payload: res.retValue })
          localStorage.setItem('userInfo', JSON.stringify(res.retValue))
      });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * 用户退出的action
 * @returns 
 */
export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_LIST_RESET })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: ORDER_LIST_MY_RESET })
  document.location.href = '/login'
}

/**
 * 用户详情Action
 * @param {*} id 
 * @returns 
 */
export const getUserDetails = (accountParam) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST })

    AccountService.getAccountDetail(accountParam)
      .then(res => {
          //console.log("getUserDetails="+JSON.stringify(res));
          if(res == null ) {
              dispatch({ 
                type: USER_DETAILS_FAIL, 
                payload: "后端服务异常"
               })
              return ;
          } else if(res.retCode !== 200) {
            dispatch({ 
              type: USER_DETAILS_FAIL, 
              payload: res.message
             })
            return ;
          }
          // 数据存储
          dispatch({ type: USER_DETAILS_SUCCESS, payload: res.retValue })
          dispatch({ type: USER_LOGIN_SUCCESS, payload: res.retValue })
          localStorage.setItem('userInfo', JSON.stringify(res.retValue))
      });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**
 * 更新用户详情Action
 * @param {*} params 
 * @returns 
 */
export const updateUserDetails = (accountParam) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST })

    AccountService.updateAccount(accountParam)
      .then(res => {
          //console.log("updateUserDetails="+JSON.stringify(res));
          if(res == null ) {
              dispatch({ 
                type: USER_UPDATE_PROFILE_FAIL, 
                payload: "后端服务异常"
               })
              return ;
          } else if(res.retCode !== 200) {
            dispatch({ 
              type: USER_UPDATE_PROFILE_FAIL, 
              payload: res.message
             })
            return ;
          }
          // 数据存储
          dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: res.retValue })
      });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}


/**
 * 更新用户密码Action
 * @param {*} params 
 * @returns 
 */
export const updateUserPassword = (accountParam) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PASSWORD_REQUEST })

    AccountService.updateAccountPassword(accountParam)
      .then(res => {
          //console.log("updateUserPassword="+JSON.stringify(res));
          if(res == null ) {
              dispatch({ 
                type: USER_UPDATE_PASSWORD_FAIL, 
                payload: "后端服务异常"
               })
              return ;
          } else if(res.retCode !== 200) {
            dispatch({ 
              type: USER_UPDATE_PASSWORD_FAIL, 
              payload: res.message
             })
            return ;
          }
          // 数据存储
          dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: res.retValue })
          // 退出登录
          localStorage.removeItem('userInfo')
          dispatch({ type: USER_LOGOUT })
          dispatch({ type: USER_LIST_RESET })
          dispatch({ type: USER_DETAILS_RESET })
          dispatch({ type: ORDER_LIST_MY_RESET })
          document.location.href = '/login'
      });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}














