import axios from "axios";
import Qs from "qs";

export default {
  /**
   * 插入订单
   * @param {*} orderParam 
   * @returns 
   */
   insertOrder(orderParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/order/insertOrder",
      data: orderParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
  
  

   /**
    * 获取订单详情
    * @param {*} orderParam 
    * @returns 
    */
    getDetailByOrderKey(orderParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/order/getDetailByOrderKey",
      data: orderParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

 
};
