import axios from "axios";
import Qs from "qs";

export default {

  /**
   * 分页获取一级地区
   * @param {*} info 
   * @returns 
   */
  getOneList() {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/area/getOneList",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取信息详情
   * @param {*} info 
   * @returns 
   */
  getSecondList(info) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/area/getSecondList",
      data: info,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
 
  
};
